import React from "react";
import Slide from "react-reveal";
import Zmage from "react-zmage";

const Resume = ({ data }) => {
  // const getRandomColor = () => {
  //   let letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  if (!data) return null;

  const services = data.services.map((service) => (
    <div key={service.serviceType}>
      <h3>{service.serviceType}</h3>
      <p className="info">
        <em className="date">{service.image}</em>
      </p>
      <Zmage alt={service.serviceType} src={"images/profilepic.jpg"} />
      <p>{service.description}</p>
    </div>
  ));

  // const prices = data.services.map((service) => (
  //   <div key={service.serviceType}>
  //     <p>{"Disznovagas - 250.000 ft"}</p>
  //   </div>
  // ));

  return (
    <section id="resume">
      <Slide left duration={500}>
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Szolgáltatások</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{services}</div>
            </div>
          </div>
        </div>
      </Slide>

      {/* <Slide left duration={1300}>
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Árak</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{prices}</div>
            </div>
          </div>
        </div>
      </Slide> */}
    </section>
  );
};

export default Resume;
