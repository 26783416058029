import React from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

const Header = ({ data }) => {
  if (!data) return null;

  const { name, description } = data;

  return (
    <header id="home">
      <ParticlesBg type="cobweb" bg={true} color="#626678" num={70}/>
      {/* <ParticlesBg type="custom" config={config} bg={true} /> */}


      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <li className="current"> 
            <a className="smoothscroll" href="#home">
              Home
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#about">
              Rólam
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#resume">
              Szolgáltatások
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#portfolio">
              Munkáim
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#contact">
              KAPCSOLAT
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <Fade bottom>
            <h1 className="responsive-headline">{name}</h1>
          </Fade>
          <Fade bottom duration={1200}>
            <h3>{description}.</h3>
          </Fade>
          <hr />
          <Fade bottom duration={2000}>
            <ul className="social" >
              <a href="#contact" className="button btn project-btn">
                <i className="fa fa-envelope"></i>Kapcsolat
              </a>
              <a href="#resume" className="button btn github-btn">
                <i className="fa fa-briefcase"></i>Munkáim
              </a>
              </ul>
          </Fade>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};

export default Header;
