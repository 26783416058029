import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-reveal";
import emailjs from "@emailjs/browser";

const Contact = ({ data }) => {
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  // const [formError, setFormError] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);



  const form = useRef();

  useEffect(() => {
    // emailjs.init("YOUR-PUBLIC-KEY-HERE");
    if (data) {
      const { name, address, phone } = data;
      setName(name);
      setStreet(address.street);
      setCity(address.city);
      setState(address.state);
      setZip(address.zip);
      setPhone(phone);
    }
  }, [data]);

  const sendEmail = (e) => {
    e.preventDefault();
    // console.log(form.current);

    setContactName('');
    setContactEmail('');
    setContactPhone('');
    setContactMessage('');

    emailjs
      .sendForm('service_5j99vs7', 'template_2lz9p99', form.current, 'SWM-blko5jsY-4zBu')
      .then(
        () => {
          console.log('SUCCESS!');
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000); 

        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <section id="contact">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Ajánlatkérés</span>
            </h1>
          </div>

          <div className="ten columns">
            <p className="lead">Ajánlatkérésért itt tudod felvenni velem a kapcsolatot.</p>
          </div>
        </div>
      </Fade>

      <div className="row">
        <Slide left duration={1000}>
          <ContactUs form={form} sendEmail={sendEmail}
           contactName={contactName} contactEmail={contactEmail} contactPhone={contactPhone} contactMessage={contactMessage}
           setContactName={setContactName} setContactEmail={setContactEmail} setContactPhone={setContactPhone} setContactMessage={setContactMessage}
          //  formError={formError} 
           showSuccessMessage={showSuccessMessage}
           />
        </Slide>

        <Slide right duration={1000}>
          <aside className="four columns footer-widgets">
            <div className="widget widget_contact">
              <h4>Kapcsolat</h4>
              <p className="address">
                {name}
                <br />
                {street} <br />
                {city}, {state} {zip}
                <br />
                <span>{phone}</span>
              </p>
            </div>
          </aside>
        </Slide>
      </div>
    </section>
  );
};

const ContactUs = ({ form, sendEmail, contactName, contactEmail, contactPhone, contactMessage, setContactName, setContactEmail, setContactPhone, setContactMessage, formError, showSuccessMessage }) => {
  return (
    
    <div className="eight columns">

      <form ref={form} onSubmit={sendEmail}>
        <fieldset>
          <div>
            <label htmlFor="contactName">Név <span className="required">*</span></label>
            <input type="text" value={contactName} onChange={(e) => setContactName(e.target.value)} size="35" id="contactName" name="user_name" required/>
          </div>

          <div>
            <label htmlFor="contactEmail">Email</label>
            <input type="text" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} size="35" id="contactEmail" name="user_email" />
          </div>

          <div>
            <label htmlFor="contactPhone">Telefon <span className="required">*</span></label>
            <input type="text" value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} size="35" id="contactPhone" name="user_phone" required/>
          </div>

          {/* <div>
            <label htmlFor="contactSubject">Tárgy</label>
            <input type="text" defaultValue="" size="35" id="contactSubject" name="contact_subject" />
          </div> */}

          <div>
            <label htmlFor="contactMessage">Üzenet <span className="required">*</span></label>
            <textarea value={contactMessage}  onChange={(e) => setContactMessage(e.target.value)} cols="50" rows="15" id="contactMessage" name="message" required></textarea>
          </div>

          {showSuccessMessage && <div id="message-success">Sikeresen felvetted velünk a kapcsolatot. Mielöbb keresni fogunk!</div>}
          {formError && <div id="message-warning">{formError}</div>}

          <div>
            <button className="submit">Ajánlatkérés</button>
            <span id="image-loader">
              <img alt="" src="images/loader.gif" />
            </span>
          </div>
        </fieldset>
      </form>


    </div>
  );
};

export default Contact;
