import React from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

const Portfolio = ({ data }) => {
  if (!data) return null;

  return (
    <section id="portfolio">
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Munkáim</h1>

            <div id="portfolio-wrapper" className="bgrid-halves s-bgrid-halves cf">
              {data.projects.map((project, index) => (
                <div key={index} className="columns portfolio-item">
                  <div className="item-wrap">
                    {/* <Zmage alt={project.title} src={"images/portfolio/" + project.image} /> */}
                    <Zmage alt={"Melo " + index} src={"images/portfolio/" + project.image} />
                    <div style={{ textAlign: "center" }}>{"Melo " + index}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Portfolio;
